<template>
    <div>
        <div class="mt-10" :class="$vuetify.breakpoint.mobile ? '' : 'container'">
            <v-row>
                <v-col
                    cols="12"
                    lg="4"
                    sm=""
                    class="px-sm-10 d-flex justify-center"
                    v-for="(item, index) in links"
                    :key="index"
                >
                    <div class="text-center">
                        <v-card
                            class="primary home-card d-flex justify-center"
                            flat
                            :to="item.slug">
                            <v-icon color="secondary" class="home-icon">{{ item.icon }}</v-icon>
                        </v-card>
                        <h3 class="third--text mt-2">{{item.title}}</h3>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            links: [
                {
                    title: "الشكاوي و المقترحات",
                    slug: "/allComplaint",
                    icon: "fi fi-br-shield-exclamation",
                    renderComponent: true,
                },
                {
                    title: "الشكاوي المكررة",
                    slug: "/repeatedComplaints",
                    icon: "fa-solid fa-arrows-rotate",
                    renderComponent: true,
                },                
                {
                    title: "حلول الشكاوي",
                    slug: "/complaint-solution",
                    icon: "fi fi-br-shield-check",
                    renderComponent: true,
                },
            ],
        };
    },

    methods: {},
};
</script>


<style lang="scss" scoped>
.container {
    width: 70%;
    margin: auto;
}
</style>














